
html, body, #root {
    height: 100%;
}


html {
    --padding-sm: 1rem;
    --padding-md: 2rem;
    --padding-lg: 5rem;
    --padding-xl: 15rem;

    --shadow: 3px 2px 3px rgba(0, 0, 0, 0.15);
    --shadow-sm: 1px 1px 2px rgba(0, 0, 0, 0.1);

    /* --primary: rgb(255 42 0); */
    --primary: #F24405;
    --secondary: #FFF;
    --tertiary: #AAFDFD;
    --quartary: #00FFFF;
    --background: #FFF;

    --max-width: 2000px;

    font-size: clamp(10px, calc(1.1vw), 1rem);
    background-color: var(--primary);
}


@supports (color: color(display-p3 0 0 0)) {
    /* html {
        --primary: color(display-p3 0.9490 0.2667 0.0196);
        --secondary: #FFF;
        --tertiary: #AAFDFD;
    } */
}


body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


header {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    max-width: var(--max-width);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-sm);
    margin: auto;
}

header a {
    color: inherit;
}

a {
    text-decoration: none;
    color: inherit;
    -webkit-text-stroke: inherit;
    transition: color 0.3s;
}@import("index.css");

a:hover {
    filter: brightness(80%)
}

button {
    max-width: 700px;
    background: inherit;
    overflow: visible;
    margin: 0;
    padding: 0;
}

video {
    background-color: black;
}

footer {
    display: flex;
    justify-content: center;
}

section {
    /* min-height: 60vh; */
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
}

.outlet {
    width: 100%;
    height: 100%;
    align-items: flex-start !important;
    box-sizing: border-box;
    max-width: var(--max-width);
    display: flex;
    padding: var(--padding-sm);
    margin: auto;
}

.container {
    flex: 1;
    max-width: var(--max-width);
    padding: var(--padding-sm) 0;
    margin: 0 0;
    /* color: var(--secondary); */
}

.textbox {
    padding: var(--padding-md);
    box-sizing: border-box;
}

.textbox p {
    margin: 0;
}

.background__block {
    width: 100%;
    background-color: var(--primary);
    height: 65rem;
}

.row,
.column {
    flex-grow: 1;
    display: flex;
}

.row {
    flex: 1;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    /* gap: 0 2rem; */
}

.column {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.leading {
    justify-content: flex-start;
}

.trailing {
    justify-content: flex-end;
}

.list {
    justify-content: center;
    align-items: center;
}

.space {
    justify-content: space-between;
}

.center {
    text-align: center;
    justify-content: center;
    align-items: center;
}

.top {
    align-items: flex-start;
}

.bottom {
    align-items: flex-end;
}

.wrap-reverse {
    flex-wrap: wrap-reverse;
}

.nowrap {
    flex-wrap: nowrap;
}

img {
    display: block;

}
