.App {
	width: 100%;
	min-height: 100%;
	display: flex;
	justify-content: center;
}

.Controls {
	width: 100%;
	/* min-height: 100%; */
	display: flex;
}

.Modal {
	position: absolute;
	top: 0;
	left: min(10rem, 8vw);
	right: min(10rem, 8vw);
	bottom: 0;
	overflow: scroll;
}

.ModalSidebar {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: min(40rem, 100%);
	display: flex;
	background-color: var(--tertiary);
	-webkit-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
}


.ModalSidebarRight {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: min(40rem, 100%);
	display: flex;
	background-color: var(--secondary);
	color: var(--quartary);
	-webkit-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
}

.Modal::-webkit-scrollbar {
	display: none;
}

.Modal:focus, .ModalSidebar:focus, .ModalSidebarRight:focus {
	outline: none;
}

.Backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 99;
}

.Backdrop:has(.close-ModalSidebar) {
	pointer-events: none;
}

.project-page {
	padding: 0 var(--padding-md);
	padding-bottom: var(--padding-lg);
}

#projects-list {
	max-height: 100vh;
}

.project-modal p {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

#process_diagram {
	width: clamp(320px, calc(30vw + 200px), 700px);
	margin: 0 auto;
	padding: var(--padding-md) 0;
}

.content__right {
	display: flex;
	flex-direction: column;
	align-items: end;
	text-align: right;
	flex: 3;
}

#hero {
	color: var(--background);
	width: 100%;
	position: relative;
	display: flex;
	overflow: hidden;
	top: -12.5rem;
	margin-bottom: -12.5rem;
}

#hero img {
	max-width: 30vw;
}

.button__grid {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
}

.button__grid a {
	flex: 1;
	min-width: 300px;
	background-color: transparent;
	border: 4px dotted var(--secondary);
	box-shadow: 3px 2px 3px rgba(0, 0, 0, 0.15);
	border-radius: 1em;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-decoration: none;
	color: inherit;
}

.button__grid p {
	margin: 0;
}

.button__grid--content {
	padding: var(--padding-sm) var(--padding-md);
}


.button__grid img {
	width: 100%;
	object-fit: cover;
}


.footer__content {
	flex: 1;
	max-width: 1500px;
	margin: 0 2rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: var(--padding-sm) 0;
	border-top: solid lightgray 1px;
}

footer .row {
	justify-content: flex-start;
	gap: 10rem;
}

.small {
	min-height: 0;
	padding-bottom: var(--padding-sm);
}


.selector-bar {
	background-color: var(--tertiary);
	/* filter: drop-shadow(1px 3px 4px rgba(0,0,0, 0.1)); */
	filter: drop-shadow(var(--shadow));
	border-radius: 10px;
	margin-top: var(--padding-md);
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 2.5rem;
	width: clamp(300px, 50vw, 1050px);
}

.selector-bar button {
	flex: 1 1;
	font-size: 1.2rem;
	font-weight: normal;
	border: none;
	padding: 0;
	background-color: transparent;
	height: 94%;
	margin: 0;
}

.selector-bar button:hover {
	font-size: 1.2rem;
	font-weight: 500;
	margin: 0;
	color: black;
	transition: font-weight 1.5s;
}

.selector-bar .selector-bar-active {
	border-radius: 8px;
	background-color: white;
	filter: drop-shadow(var(--shadow-sm));
	z-index: 100;
}

#capability_frame {
	margin-top: 3rem;
	width: min(80vw, 1200px);
	height: min(45vw, 675px);
	background-color: black;
	border-radius: 25px;
	filter: drop-shadow(var(--shadow));
}

#capability_frame img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	border-radius: inherit;
}

.row h4 {
	margin-bottom: 0.6rem;
}

#copyright {
	margin-top: var(--padding-sm);
}

#about {
	width: 100%;
}


#homepage {
	overflow: hidden;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: var(--padding-sm) 0;
	cursor: default;
}

#homepage .t1 {
	white-space: nowrap;

}

#homepage .t1:first-child {
	line-height: 1em;
	margin-top: -0.12em;
}

#homepage .t1:last-child {
	line-height: 1em;
	margin-bottom: -0.26em;
}

.t1 span {
	color: transparent;
	-webkit-text-stroke: 0.1vh white;
}

/* h1 span:hover {
	 color: var(--secondary);
} */

.t1:has(#about) {
	/* transform: translateX(-600px); */
}

.t1:has(#projects) {
	/* transform: translateX(400px); */
}

.t1:has(#shop) {
	/* transform: translateX(calc(-710px)); */
}


.pill {
	cursor: pointer;
	border: 1px solid var(--secondary);
	width: 1em;
	height: 0.5em;
	margin: auto 0;
	margin-right: var(--padding-sm);
	border-radius: 100px;
	display: flex;
	flex-direction: row;
}

.pill .circle {
	height: calc(0.5em - 2px);
	width: calc(0.5em - 2px);
	background-color: white;
	border-radius: 0.5em;
	border: 1px solid transparent;
	box-sizing: border-box;
	margin: 1px;
	transition: transform 0.5s;
}

.circle-right {
	transform: translateX(calc(100% + 2px));
}

#headerRow {
	flex: 1;
	padding: 0 var(--padding-md);
	padding-top: var(--padding-sm);
}

.grid-row {
	height: min(20vw, calc(0.2 * var(--max-width)));
}

.col2 {
	flex: 2;
	max-height: inherit;
	min-width: 360px;
	box-sizing: border-box;
	/* overflow: hidden; */
}

.col1 {
	flex: 1;
	max-height: inherit;
	min-width: 190px;
	box-sizing: border-box;
}

#about .container {
	padding: 0;
}

.shrink {
	flex-shrink: 1;
}

#secateurs {
	object-fit: scale-down;
	height: 150px;
	aspect-ratio: 1;
	position: absolute;
	left: calc(-0.5 * min(100vw, var(--max-width)));
	transform: translate(75px, -150px);
	pointer-events: none;
}


@media (max-width: 820px) {
	.shrink {
		display: none;
	}

	.grid-row {
		height: max(20vw, calc(-20vw + 330px));
	}

	.t3 {
		font-size: clamp(3.6rem, 5.5vw, 8rem);
	}
}

@media (max-width: 1167px) {
	#a123, #b124 {
		display: none;
	}
	#secateurs {
		position: static;
		display: block;
		margin-top: var(--padding-lg);
		transform: unset;
	}
}


div:has(img) {

}

img {
	width: 100%;
	height: inherit;
	aspect-ratio: initial;
	object-fit: cover;
}

.project-image img, .project-video video {
	width: 100%;
	max-height: 93vh;
	aspect-ratio: initial;
	margin: 1rem auto;
	object-fit: contain;
}

.project-modal {
	padding: 4rem 0;
	color: var(--secondary);
}

video:focus {
	border: none;
	outline: none;
}

#x {
	position: fixed;
	right: min(10rem, 8vw);
	margin-right: -0.2em;
	top: 0.5rem;
	color: var(--secondary);
}

.x:hover {
	filter: brightness(80%);
}

.x {
	font-size: 3rem;
	color: inherit;
	cursor: pointer;
}

.contact .t5 {
	text-transform: uppercase;
	color: transparent;
	-webkit-text-stroke: 0.03rem var(--primary);
	font-size: 1.5rem;
	line-height: 1.5em;
}

.contact a:hover {
	filter:none;
	color: var(--primary);
}

.t1 a:hover {
	filter: none;
	color: var(--secondary);
}

.social .t4 {
	line-height: 1.5em;
	-webkit-text-stroke-color: var(--primary);
}

.social a:hover {
	color: var(--primary);
	filter: none;
}

.social {
	margin-bottom: var(--padding-sm);
}

.team h5 {
	color: var(--tertiary);
	font-size: 2.1rem;
}

.vertical {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-content: space-between;
	height: 100%;
	width: 100%;
	padding: 0 1rem;
}

.vertical .tp {
	text-transform: uppercase;
	line-height: 2.4em;
	padding: 0;
	margin: 0;
}

#socialIcons {
	font-size: 3.4rem;
	padding: var(--padding-md) 0;
	width: 70%;
}

.links .t2 {
	line-height: 5rem;
}

#text-1 {
	min-height: min(calc(2.5 * 20vw), calc(2.5 * 0.2 * var(--max-width)));
}

#text-2 {
	padding-left: var(--padding-md);
}

.sidebar {
	padding: var(--padding-sm);
}

#a123 {
	position: relative;
	top: -100%;
	left: 100%;
}

#b124 {
	position: relative;
	top: -310%;
	left: 100%;
	width: 60%;
	padding: 20%;
	opacity: 1;
	object-fit: scale-down;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
	animation-duration: 0.1s;
}

#about img, #about p, #about h2 {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
	animation-duration: 0.1s;
}

#project-item {
	font-size: 0.7em;
}

#image3 {
	width: 100%;
}

#bushy-logo {
	cursor: pointer;
}

#project-possum {
	position: fixed;
	box-sizing: content-box;
	height: 30vh;
	width: max(20rem,
		calc(20rem + calc(0.5 * (100vw - var(--max-width))))
	);
	bottom: 0;
	right: 0;
	overflow: hidden;
	z-index: 1;
}

#project-possum img {
	height: 12rem;
	width: 15rem;
	bottom: -1rem;
	left: 5rem;
	position: absolute;
	object-fit: contain;
	transform: translateY(40%);
}
/*
#project-possum:hover {
	transform: translateY(40%);
	transition: transform 0.3s;
} */

#homepage-possum {
	height: 100px;
	width: 100px;
	position: fixed;
	top: 0;
}


#bushy {
	color: inherit;
	-webkit-text-stroke: none;
	cursor: default;
	animation: none;
	position: relative;
}


@media (min-width: 1100px) and (min-height: 667px) {
	#bushy-possum {
		position: absolute;
		display: inline-block;
		bottom: 0.21em;
		height: 0.55em;
		width: 1em;
		left: 0.1em;
		background-image: url("../../public/images/possum.png");
		background-position: bottom left;
		background-repeat: no-repeat;
		background-size: contain;
		/* transition: transform 0.7s ease-out; */
		/* transition-delay: 0.1s; */
		clip-path: inset(0 15%);
		overflow: hidden;
	}

	#bushy-possum::before {

	}

	/* #bushy:hover::before {
		transform: scale(0.63, 1) translate(-45.5%, 8%);
		transition: transform 0.3s ease-out;
		transition-delay: 0;
	} */

	#bushy::after {
		content: "";
		position: absolute;
		display: inline-block;
		background-image: url("../../public/images/clip.svg");
		width: 0.637em;
		height: 0.72em;
		bottom: 0.2em;
		left: 0.04em;
		background-position: bottom left;
		background-repeat: no-repeat;
		background-size: contain;
		z-index: 100;
	}
}

#coming-soon {
	transform: translateY(calc(-104px - 50%));
}
