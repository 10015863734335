@font-face {
	font-family: "Muller";
	font-weight: bolder;
	src: url("../../public/fonts/MullerBlack.woff2"),
		 url("../../public/fonts/MullerBlack.ttf");
}

@font-face {
	font-family: "AzoSans";
	font-style: normal;
	font-weight: 400;
	src: url("../../public/fonts/AzoSans-Regular.woff2"),
		 url("../../public/fonts/AzoSans-Regular.ttf");
}

@font-face {
	font-family: "AzoSans";
	font-style: normal;
	font-weight: 200;
	src: url("../../public/fonts/AzoSans-Light.woff2"),
		 url("../../public/fonts/AzoSans-Light.ttf");
}

@font-face {
	font-family: "AzoSans";
	font-style: normal;
	font-weight: 600;
	src: url("../../public/fonts/AzoSans-Bold.woff2"),
		 url("../../public/fonts/AzoSans-Bold.ttf");
}

@font-face {
	font-family: "AzoSans";
	font-style: normal;
	font-weight: 900;
	src: url("../../public/fonts/AzoSans-Black.woff2"),
		 url("../../public/fonts/AzoSans-Black.ttf");
}


.t1, .t2, .t3, .t4 {
	font-family: Muller;
	font-weight: bolder;
	line-height: 1em;
	letter-spacing: -.15rem;
	font-kerning: normal;
	margin: 0 0;
	padding: 0 0;
	font-smooth: antialiased;
	-webkit-font-smoothing: antialiased;
	font-synthesis: none;
}

.t1 {
	font-size:
		min(80vw / 5.4, calc((100vh - 2*var(--padding-md)) / 5));
	letter-spacing: calc(-0.15 * 16px);
}

.t1, .t2 {
	text-transform: uppercase;
}

.t2 {
	font-size: 3.5rem;
	letter-spacing: 0.06em;
}

.t3, .t4 {
	font-size: clamp(2.5rem, 3.5vw, 4.5rem);
	text-transform: uppercase;
	-webkit-text-stroke: 0.04rem var(--secondary);
	text-rendering: geometricPrecision;
	text-align: center;
	letter-spacing: 0.15rem;
	color: transparent;
	/* padding-bottom: 1rem; */
}

#project-item {
	text-align: left;
}

.t4 {
	font-size: clamp(2.3rem, 2.4vw, 3rem);
}

header h3 {
	-webkit-text-stroke: none;
	color: inherit;
	padding: 0;
	margin: 0;
}

#hero h3 {
	color: inherit;
}

h3 svg {
	margin: auto;
	align-self: center;
}

h4 {
	/* font-size: 2rem; */
	text-transform: none;
	-webkit-text-stroke: 0.03rem white;
	text-rendering: geometricPrecision;
	font-size: clamp(2rem, 2.5vw, 3.5em);
}

.t5, h5, h6 {
	font-family: AzoSans;
	text-decoration: none;
	font-weight: bold;
	color: inherit;
	letter-spacing: .01rem;
	font-size: 1.2rem;
	margin: 0;
}

h6 {
	font-size: 1rem;
}

p, ul, .tp {
	font-family: AzoSans;
	font-weight: light;
	letter-spacing: .05rem;
	font-size: 1.1rem;
	margin-bottom: 1rem;
	color: inherit;
}

ul {
	margin-left: 0;
	padding-left: 0;
}

li {
	margin-left: calc(1rem + 5px);
}

button {
	font-family: AzoSans;
	font-weight: bold;
	letter-spacing: .05rem;
	font-size: 1.2rem;
	color: inherit;
	max-width: 700px;
	text-transform: none;
	line-height: 1.15;
}

.icon {
	font-size: 0.8em;
}

/* @media (max-width: 1370px) {
	h1, h2, h3, h4, h5, h6 {
		text-align: left;
	}
	.row, .textbox, .column {
		align-items: start;
		justify-content: start;
	}
	#homepage {
		align-items: start;
	}

	.container {
		margin: 0;
	}
} */
