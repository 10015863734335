
.ModalSidebar {
	animation-name: sidebar-slide-right;
	animation-timing-function: ease-in-out;
	animation-duration: 0.5s;
}

@keyframes sidebar-slide-right {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0);
	}
}

.close-ModalSidebar {
	animation-name: sidebar-slide-left;
	animation-timing-function: ease-in-out;
	animation-duration: 500ms;
	pointer-events: none;
}

@keyframes sidebar-slide-left {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-100%);
	}
}

@keyframes cart-slide-left {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0);
	}
}

@keyframes cart-slide-right {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(100%);
	}
}

.ModalSidebarRight {
	animation-name: cart-slide-left;
	animation-timing-function: ease-in-out;
	animation-duration: 500ms;
}

.close-ModalSidebarRight {
	animation-name: cart-slide-right;
	animation-timing-function: ease-in-out;
	animation-duration: 0.5s;
	pointer-events: none;
}


#homepage span :not(#bushy, #bushy-possum) {
	animation-name: fade-links;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;
}

@keyframes fade-links {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

#bushy {
	opacity: 1;
	animation: none;
}

